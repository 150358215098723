import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-center align-items-center" }
const _hoisted_2 = { class: "login-container d-flex justify-content-center align-items-center flex-column" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "font-size-16 font-weight-light font-color-label" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "font-size-16 font-weight-light font-color-label" }
const _hoisted_7 = ["type"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  class: "text-monza-500 font-weight-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchoolLogo = _resolveComponent("SchoolLogo")!
  const _component_b_alert = _resolveComponent("b-alert")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_Validation = _resolveComponent("Validation")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_GuestLayout = _resolveComponent("GuestLayout")!

  return (_openBlock(), _createBlock(_component_GuestLayout, { "hide-logo": true }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: require('@/assets/img/new_logo.svg'),
            alt: "Logo",
            class: "mt-3 mb-5",
            style: {"height":"70px"}
          }, null, 8, _hoisted_3),
          (_ctx.drivingSchoolId)
            ? (_openBlock(), _createBlock(_component_SchoolLogo, {
                key: 0,
                "driving-school-id": _ctx.drivingSchoolId,
                style: {"margin-bottom":"5rem !important","margin-top":"2rem !important"}
              }, null, 8, ["driving-school-id"]))
            : _createCommentVNode("", true),
          _createElementVNode("form", {
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
          }, [
            _createVNode(_component_b_row, { class: "px-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_alert, {
                      show: _ctx.hasError,
                      variant: "danger",
                      class: "w-100"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Eingegebene E-Mail oder Passwort ist falsch. ")
                      ]),
                      _: 1
                    }, 8, ["show"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_col, {
                  cols: "12",
                  class: "form-group"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("auth.email")) + "* ", 1),
                    _createVNode(_component_Validation, {
                      validations: _ctx.v$.username,
                      "show-errors": true
                    }, {
                      default: _withCtx(({ invalid }) => [
                        _createElementVNode("div", _hoisted_5, [
                          _withDirectives(_createElementVNode("input", {
                            id: "username",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                            class: _normalizeClass(["form-control login-input", { 'is-invalid': invalid }])
                          }, null, 2), [
                            [
                              _vModelText,
                              _ctx.username,
                              void 0,
                              { trim: true }
                            ]
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["validations"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_col, {
                  cols: "12",
                  class: "form-group"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("auth.password")) + "* ", 1),
                    _createVNode(_component_Validation, {
                      validations: _ctx.v$.password,
                      "show-errors": true
                    }, {
                      default: _withCtx(({ invalid }) => [
                        _createElementVNode("div", {
                          class: _normalizeClass(["d-flex position-relative password-form-control", { invalid: invalid }])
                        }, [
                          _withDirectives(_createElementVNode("input", {
                            id: "password",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                            class: _normalizeClass(["form-control login-input", { 'is-invalid': invalid }]),
                            type: [_ctx.showPassword ? 'text' : 'password']
                          }, null, 10, _hoisted_7), [
                            [
                              _vModelDynamic,
                              _ctx.password,
                              void 0,
                              { trim: true }
                            ]
                          ]),
                          _createElementVNode("button", {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword)),
                            class: "show-password-btn pr-2",
                            type: "button"
                          }, [
                            _createElementVNode("i", {
                              class: _normalizeClass(["fa-thin fa-fw", [_ctx.showPassword ? 'fa-eye-slash' : 'fa-eye']])
                            }, null, 2)
                          ])
                        ], 2)
                      ]),
                      _: 1
                    }, 8, ["validations"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_col, {
                  cols: "6",
                  class: "form-group remember"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_checkbox, {
                      id: "remember",
                      modelValue: _ctx.remember,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.remember) = $event)),
                      name: "remember",
                      value: "1",
                      "unchecked-value": "0",
                      class: "login-remember",
                      "button-variant": "success",
                      onChange: _ctx.onRememberMeChange
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("auth.remember")), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_col, {
                  cols: "6",
                  class: "text-right form-group"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/forgot-password" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("auth.password_forgot")) + "? ", 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", {
                      class: "login-btn font-size-18",
                      disabled: _ctx.loading
                    }, [
                      (_ctx.loading)
                        ? (_openBlock(), _createBlock(_component_b_spinner, {
                            key: 0,
                            small: ""
                          }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("auth.login")), 1)
                    ], 8, _hoisted_8),
                    (_ctx.version)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.version), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 32)
        ])
      ])
    ]),
    _: 1
  }))
}